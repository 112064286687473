import { default as _91_46_46_46slug_93nBk4goCtkuMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/[...slug].vue?macro=true";
import { default as autologinkZlQqzIhafMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/auth/autologin.vue?macro=true";
import { default as callbackEtMFOLcDjuMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/auth/callback.vue?macro=true";
import { default as bettingJu3hFE0vCaMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/betting.vue?macro=true";
import { default as _91pageIdentity_9352MQ38lcI4Meta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/bonus/[pageIdentity].vue?macro=true";
import { default as contact8wVkavEQdlMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/contact.vue?macro=true";
import { default as favorites_46clientBRInaOlvbwMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/nabcasino/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93cJZKNj4EhAMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/games/[id].vue?macro=true";
import { default as indexGArLuyW2Q6Meta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/games/index.vue?macro=true";
import { default as indexphsperuZ8oMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/index.vue?macro=true";
import { default as loyaltyTZk8YUjFcxMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/loyalty.vue?macro=true";
import { default as mainNOFGI7cJtzMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/main.vue?macro=true";
import { default as bonusesRL2v5YPJu7Meta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/bonuses.vue?macro=true";
import { default as documentsfqGNhgcAURMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/documents.vue?macro=true";
import { default as historyjrfceZULxuMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/history.vue?macro=true";
import { default as infofHSREDXgQiMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/info.vue?macro=true";
import { default as limitswNyzIyizP3Meta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/limits.vue?macro=true";
import { default as notificationsSaDPsg4jQ6Meta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/notifications.vue?macro=true";
import { default as securityGoCZj063TvMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/security.vue?macro=true";
import { default as verificationTuUOeu9NFHMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/verification.vue?macro=true";
import { default as walletih7qm9lwt4Meta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/wallet.vue?macro=true";
import { default as profile_46clientfZBUwoRY8iMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile.client.vue?macro=true";
import { default as providersOLmgGHHO33Meta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/providers.vue?macro=true";
import { default as _91pageIdentity_935DWWYDsV2pMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsdc94QMBj9aMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/questions.vue?macro=true";
import { default as recently_45played_46clienti7axc1nIQAMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_930gozpSfQswMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/static/[pageIdentity].vue?macro=true";
import { default as welcome_45packagee8Is9eNaKnMeta } from "/builds/platform/customer-frontends/nabcasino/skeleton/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/games/[id].vue")
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/games/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/loyalty.vue")
  },
  {
    name: "main",
    path: "/main",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/main.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile.client.vue")),
    children: [
  {
    name: "profile-bonuses",
    path: "bonuses",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/bonuses.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsdc94QMBj9aMeta || {},
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/static/[pageIdentity].vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/nabcasino/skeleton/pages/welcome-package.vue")
  }
]